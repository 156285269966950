import * as React from "react"
import GlobalStyle from "../theme/GlobalStyle";
import Header from "../components/Header";
import {Helmet} from "react-helmet";
import favicon from '../images/favicon.png';
import TopBar from "../components/TopBar";
import styled from "styled-components";

const Message = styled.h1`
  text-align: center;
  margin-top: 40px;
  font-size: 40px;
`

// markup
const NotFoundPage = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Picars</title>
                <link rel="icon" href={favicon}/>
                <link rel="canonical" href="https://picars.pl"/>
            </Helmet>
            <TopBar/>
            <Header/>
            <Message>404, strona nie istnieje :(</Message>
            <GlobalStyle/>
        </div>
    )
}

export default NotFoundPage
